import React, { useEffect, useRef } from 'react';
import './App.css';

function Question(props) {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const pageRef = useRef(null);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.style.gridTemplateAreas = props.question.image
        ? '"header header" "main-question images" "footer_q footer_q"'
        : '"header header" "main-question main-question" "footer_q footer_q"';
    }
  }, [props.question.image]);

  return (
    <div ref={pageRef} className="page">
      <React.Fragment>
        <div className="main-question">
          <div className="question-title">
            <span dangerouslySetInnerHTML={{ __html: props.question.text[props.language] }}></span>
          </div>
          <div className="question-description">{props.question.description[props.language]}</div>
          {props.question.options.map((option, index) => (
            <button 
              id={'option_' + props.question.id + '_' + index} 
              key={'option_' + props.question.id + '_' + index} 
              ref={ref => props.divRef.current[index] = ref} 
              className="question-button" 
              onClick={(event) => props.handleOptionClick(option, event)}
            >
              <span className="letter">{letters[index]}</span>{option.text[props.language]}
            </button>
          ))}
          <div style={{ marginLeft: 35 }}>
            <button className="button_start" onClick={() => props.nextQuestionOrResult()} hidden={props.hiddenButton}>
              {props.textButton}
            </button>
          </div>
        </div>
        {props.question.image && (
          <div className="images">
            <img src={props.question.image} alt="Current option" />
          </div>
        )}
        <div className="footer_q">
        </div>
      </React.Fragment>
    </div>
  );
}

export default Question;
