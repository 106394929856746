import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import Question from './Question';
import Home from './Home';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const defaultLanguage = urlParams.get('defaultLanguage') || 'es';
  const idURL = urlParams.get('idURL') || 'https://idcatmobil.cat';
  const logo = urlParams.get('logo') || 'logo.png';

  const [urlMapping, setUrlMapping] = useState({
    'informationURL': urlParams.get('informationURL') || 'https://videoatencion.com',
    'citizenRegistrationURL': urlParams.get('citizenRegistrationURL') || 'https://videoatencion.com',
    'newbornRegistrationURL': urlParams.get('newbornRegistrationURL') || 'https://videoatencion.com'
  });

  const viewportWidth = urlParams.get('viewportWidth');
  const viewportHeight = urlParams.get('viewportHeight');

  if (viewportWidth && viewportHeight) {
    const appRoot = document.getElementById('root');
    appRoot.style.width = `${viewportWidth}px`;
    appRoot.style.height = `${viewportHeight}px`;
  }

  const [data, setData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState(defaultLanguage);

  const [isLoading, setIsLoading] = useState(true);
  const [main, setMain] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);

  const [selected, setSelected] = useState(null);
  const [textButton, setTextButton] = useState('');
  const [userPath, setUserPath] = useState([]);
  const [questionHistory, setQuestionHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const divRef = useRef([]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleOptionClick = (option, e) => {
    if (e.target.classList.contains("intermitente")) {
      e.target.classList.remove("intermitente");
      setSelected(null);
    } else {
      divRef.current.forEach(element => {
        if (element) element.classList.remove("intermitente");
      });
      e.target.classList.add("intermitente");
      setSelected(option);
    }
    setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: option }]);
  }

  const nextQuestionOrResult = () => {
    divRef.current = [];
    if ('nextQuestion' in selected) {
      const nextQuestion = data.questions.find(q => q.id === selected.nextQuestion);
      setCurrentQuestion(nextQuestion);
      setSelected(null);
    } else if ('result' in selected) {
      setSelected(null);
      const result = data.results.find(r => r.id === selected.result);
      setResult(result);
      setCurrentQuestion(null);
    }
    const filteredPath = userPath.filter(item => item.questionId !== currentQuestion.id);
    setUserPath([...filteredPath, { questionId: currentQuestion.id, selectedOption: selected }]);
    setQuestionHistory([...questionHistory, currentQuestion]);
    setCurrentIndex(currentIndex + 1);
  }

  useEffect(() => {
    fetch('flow.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setCurrentQuestion(data.questions[0]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selected != null) {
      if ('result' in selected) {
        setTextButton(data.home.sendButton[language]);
      } else {
        setTextButton(data.home.okButton[language]);
      }
      setHiddenButton(false);
    } else {
      setHiddenButton(true);
    }
  }, [selected]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const goToTest = () => {
    setMain(false);
  };

  const goBack = () => {
    const prevQuestion = questionHistory[currentIndex - 1];
    setCurrentQuestion(prevQuestion);
    setCurrentIndex(currentIndex - 1);
    setQuestionHistory(questionHistory.slice(0, -1));
  };

  const resetFlow = () => {
    setCurrentQuestion(data.questions[0]);
    setQuestionHistory([]);
    setCurrentIndex(0);
  };

  if (main) {
    return (
      <div className="welcome">
        <Home home={data.home} langs={data.languages} language={language} idURL={idURL} goToTest={goToTest} handleLanguageChange={handleLanguageChange} logo={logo}></Home>
      </div>
    );
  } else if (result) {
    return (
      <div className="welcome">
        <div className="header">
          <img src={"/" + logo} data-qa="brand-logo" className="logo" alt=""/>
        </div>
        <React.Fragment>
          <div className="principal">
            <div className="welcome_content">
              <div className="description" dangerouslySetInnerHTML={{ __html: result.text[language] }}></div>
              <br />
              <div>
                {userPath.map((item, index) => (
                  item.selectedOption.documentation && (
                    <div key={index}>
                      <div dangerouslySetInnerHTML={{ __html: '<strong>' + item.selectedOption.documentation[language].replace(/\n/g, '<br />') + '</strong>' }}></div>
                    </div>
                  )
                ))}
              </div>
              <br />
              <a onClick={() => { window.parent.location = urlMapping[result.link] || '#' }}>
                <button className="button_start" dangerouslySetInnerHTML={{ __html: result.button[language] }}></button>
              </a>
              <a href=""><button className="button_start">Reset</button></a>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="header">
          <img src={"/" + logo} data-qa="brand-logo" className="logo" alt=""/>
        </div>
        <Question question={currentQuestion} 
          handleOptionClick={handleOptionClick}
          language={language}
          divRef={divRef}
          okButton={data.home.okButton}
          hiddenButton={hiddenButton}
          nextQuestionOrResult={nextQuestionOrResult}
          textButton={textButton}
        ></Question>
      </React.Fragment>
    );
  }
}

export default App;
